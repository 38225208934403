.otp-container {
  justify-content: space-around;

  > div {
    flex-grow: 1;
    padding-left: .5em;
    padding-right: .5em;
  }

  input.text {
    flex-grow: 1;
    padding: 0.25em 0 0;
    height: 1.85em;
    font-weight: $bold;
    transform: scale3d(1, 1, 1);
    transition: transform $transition-duration $transition-ease;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;

    &:not(.disabled):not(.is-danger):focus {
      transform: scale3d(1.2, 1.2, 1);
    }
  }
}


.docs-section-content .vertical.section {
  white-space: unset;
}
