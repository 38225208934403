// ----
//  Core Configuration Variable Overrides
//  --
//  Insert here are variable that must override global core settings
// ----

// ----
//  Inject and Use Custom Font
// ----
$inject-custom-font: true;
$custom-font-name: 'Univia Pro';
$custom-font-light-weight: 300;
$custom-font-regular-weight: 400;
$custom-font-semi-bold-weight: 600;
$custom-font-bold-weight: 700;


// ----
//  Import Initial Configuration Files
// ----
@import '~@appbuckets/react-ui/styles/config-core';
@import '~@appbuckets/react-ui/styles/_core/functions/all';

// ----
//  Color Variables Override
//  --
//  Insert here all color variable that must override the core default
// ----
$primary: #00569d;
$secondary: rgb(36, 41, 47);

$body-background: $secondary;

// ----
//  Colors Import
// ----
@import '~@appbuckets/react-ui/styles/_core/colors';
@import '~@appbuckets/react-ui/styles/_core/typography';
@import '~@appbuckets/react-ui/styles/_core/variables';


// ----
//  Variable Override per Elements
//  --
//  Insert here all elements variable
// ----
