@use 'sass:math';

/******
    Docs Page
******/
#docs {
  display: flex;
  width: 100%;
}


/******
    Docs TOC
******/
#toc-sidebar {
  height: 100vh;
  box-sizing: border-box;
  width: $sidebar-width;
  flex: 0 0 $sidebar-width;
  background-color: $white-shade;
  display: none;
  flex-direction: column;
  -webkit-backface-visibility: hidden;
  padding: $layout-top-padding 0;
  overflow-y: hidden;
  overflow-x: hidden;


  // ----
  //  Sidebar is visible on Tablet up screen only
  // ----
  @include min-screen('desktop') {
    display: flex;
  }


  // ----
  //  Logo
  // ----
  .logo {
    padding: 0 $sidebar-horizontal-padding;

    > img {
      width: math.div($sidebar-width, 2);
    }
  }


  // ----
  //  Filters
  // ----
  .filter {
    margin-top: $sidebar-section-spacing;
    padding: 0 $sidebar-horizontal-padding;
  }


  // ----
  //  TOC
  // ----
  .toc {
    overflow-y: scroll;
    flex-grow: 1;
    margin-top: $sidebar-section-spacing;

    .vertical.section {
      margin: 1em 0;
      border-top: solid 1px $cloud-light;
    }

    ul > li > a,
    .vertical.section > .label,
    .collapsable > .trigger > span {
      color: $text-secondary;
      box-sizing: border-box;
      margin: 0;
      padding: $toc-element-vertical-padding $sidebar-horizontal-padding;
      display: flex;
    }

    ul > li > a,
    .collapsable > .trigger > span {
      cursor: pointer;
      transition: color $transition-ease $transition-duration;

      &:hover {
        color: $text;
      }
    }

    ul > li > a > span > .label {
      font-size: .85em;
      margin-right: .5em;
    }

    ul > li > a > span {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      width: auto;
      overflow-y: hidden;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .collapsable > .content > ul > li > a {
      padding-top: math.div($toc-element-vertical-padding, 2);
      padding-bottom: math.div($toc-element-vertical-padding, 2);
      padding-left: $sidebar-horizontal-padding * 1.5;

      > span {
        font-size: .85em;
      }
    }
  }
}


/******
    Docs Content
******/
#docs-content {
  height: 100vh;
  width: 100%;
  flex: 1 0 100%;
  overflow-x: hidden;
  overflow-y: scroll;


  // ----
  //  Adjust content width when sidebar is visible
  // ----
  @include min-screen('desktop') {
    width: calc(100% - #{$sidebar-width});
    flex: 1 0 calc(100% - #{$sidebar-width});
  }


  // ----
  //  Docs Section
  // ----
  > .docs-section {

    // ----
    //  Set Doc Section Level Display
    // ----
    &.level-2 {
      > .docs-section-content,
      > .docs-section-code {
        padding-top: math.div($docs-section-vertical-padding, 2);
        padding-bottom: 0;
      }

      > .docs-section-content {
        border-top: solid 1px $cloud-light;
      }

      > .docs-section-content > .header {
        color: $cloud;
        font-size: .75em;
      }
    }

    &.level-3 {
      > .docs-section-content,
      > .docs-section-code {
        padding-top: math.div($docs-section-vertical-padding, 4);
        padding-bottom: math.div($docs-section-vertical-padding, 4);
      }

      > .docs-section-content > .header,
      > .docs-section-code > .header {
        font-size: .5em;
      }
    }


    // ----
    //  Set Style For Request Header
    // ----
    &.is-request {
      .header {
        font-family: $font-family-monospace;
        margin: 0;
        display: block;
        padding: 1em 1.5em;
        color: $text-secondary;
        word-break: break-all;
        line-height: 1.5;
        @include smoothShadow(1);
        border-radius: $border-radius-normal;

        .header-content {
          display: flex;
          align-items: center;
        }

        .label {
          margin-right: .5em;
        }
      }

      > .docs-section-content .header {
        background-color: $white-shade;
      }

      > .docs-section-code .header.is-success {
        background-color: transparentize($success, .5);
        color: $white-shade-2;
      }

      > .docs-section-code .header.is-danger {
        background-color: transparentize($danger, .5);
        color: $white-shade-2;
      }

      > .docs-section-code .header:not(:first-child) {
        margin-top: 5em;
      }

      > .docs-section-content,
      > .docs-section-code {
        padding-bottom: $docs-section-vertical-padding;
      }
    }


    // ----
    //  Divide Screen only in some resolution
    // ----
    @include min-screen('large-desktop') {
      display: flex;
      align-items: stretch;

      > .docs-section-content {
        flex: 0 0 60%;
      }

      > .docs-section-code {
        flex: 0 0 40%;
      }
    }


    // ----
    //  Description Styling
    // ----
    > .docs-section-content {
      background-color: $white;

      /// Base Style plain Text
      > p,
      > div > p,
      > .box > .section > .content > div > p {
        line-height: 1.5;

        + p {
          margin-top: 1.5em;
        }
      }

      > ul,
      > div > ul,
      > .box > .section > .content > div > ul {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 1em;

        > li:not(:first-child) {
          margin-top: .5em;
        }
      }

      /// Style code Snipped
      .code {
        display: block;
        padding: .5em .75em;
        font-family: $font-family-monospace;
        background-color: $cloud-light;
        color: $text-secondary;
        word-break: break-all;
        line-height: 1.5;
        margin: 1em 0;
        @include smoothShadow(1);
        border-radius: $border-radius-normal;
      }
    }


    // ----
    //  Code Section
    // ----
    > .docs-section-code {
      @include max-screen('desktop') {
        background-color: $secondary;
      }

      .tabs {
        margin-top: 1em;

        pre {
          white-space: pre-wrap;
          background-color: rgba(255, 255, 255, 0.1);
        }

        code {
          background-color: transparent;
        }

        code, pre {
          color: $white-shade-2;
          line-height: 1.5;
        }
      }
    }


    // ----
    //  Set Sections and Content Padding
    // ----
    > .docs-section-content,
    > .docs-section-code {
      padding: $docs-section-vertical-padding $docs-section-horizontal-padding;
    }
  }
}
