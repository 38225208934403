$layout-top-padding: 2em;

$sidebar-width: 280px;
$sidebar-horizontal-padding: 1.5em;
$sidebar-section-spacing: 2.5em;

$docs-section-vertical-padding: 3.5em;
$docs-section-horizontal-padding: 2.5em;

$toc-element-vertical-padding: 1em;
